import React, { Component } from 'react';
import './BottleTab.css';

import * as BabyTrackerActions from '../Actions/BabyTrackerActions.js';
import BottleStore from '../Stores/BottleStore.js';

class BottleTab extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      bottleAmount: 1,
      bottles: BottleStore.getBottles()
    };
    
    this.clearAddBottle = this.clearAddBottle.bind(this);
    this.confirmAddBottle = this.confirmAddBottle.bind(this);
    this.cancelAddBottle = this.cancelAddBottle.bind(this);
    this.addBottleValueChanged = this.addBottleValueChanged.bind(this);
    this.updateBottles = this.updateBottles.bind(this);
  }

  componentDidMount() {
    BottleStore.on("bottlesUpdated", this.updateBottles);
  }

  componentWillUnmount() {
    BottleStore.removeListener("bottlesUpdated", this.updateBottles);
  }

  updateBottles() {
    this.setState({bottles: BottleStore.getBottles()});
  }

  clearAddBottle() {
    this.setState({bottleAmount: 1});
    this.refs.bottle_slider.MaterialSlider.change(1);
  }

  confirmAddBottle() {

    var bottle = {};
    
    bottle['amount'] = this.state.bottleAmount;
    bottle['timestamp'] = Date.now();

    BabyTrackerActions.addBottle(bottle);

    this.clearAddBottle();
    
    var dialog = document.getElementById('bottle-dialog');
    dialog.close();

  }
  
  cancelAddBottle() {

    this.clearAddBottle();

    var dialog = document.getElementById('bottle-dialog');
    dialog.close();
  }
  
  addBottleValueChanged() {
    var slider = document.getElementById('feeding-amount');

    this.setState({bottleAmount: parseInt(slider.value, 10)});
  }
  
  addBottle() {
    var dialog = document.getElementById('bottle-dialog');
    dialog.showModal();
  }
  
  deleteBottle(index) {
    BabyTrackerActions.deleteBottle(index);
  }
  
  printDate(timestamp) {
    var date = new Date(timestamp);
    
    const month = date.toLocaleString('en-us', { month: 'short' });
    const day = date.getDay();
    //const year = date.getYear();
    
    return(
      <span className="">{month} {day}</span>
      );
  }
  
  render() {
    return(
      <div className="page-content">
      
        {this.state.bottles.map((bottle, index) => (
            <li className="mdl-list__item" key={index}>
              <span className="mdl-list__item-primary-content">
                { this.printDate(bottle.timestamp) }
                { bottle.amount === 0 ? <span className="bottle-list-icon" role="img" aria-label="A little milk">🍼</span> : null }
                { bottle.amount === 1 ? <span className="bottle-list-icon" role="img" aria-label="A normal amount of milk">🍼🍼</span> : null }
                { bottle.amount === 2 ? <span className="bottle-list-icon" role="img" aria-label="A lot of milk">🍼🍼🍼</span> : null }
              </span>
              <a className="mdl-list__item-secondary-action" href="#"><i className="material-icons delete-icon" onClick={() => this.deleteBottle(bottle.key)}>delete</i></a>
            </li>
          )
        )} 
          
        <dialog className="mdl-dialog" id="bottle-dialog">
  
          <div className="feeding-amount">
            { this.state.bottleAmount === 0 ? <span role="img" id="feeding-amount-1" aria-label="A little milk">🍼</span> : null }
            { this.state.bottleAmount === 1 ? <span role="img" id="feeding-amount-2" aria-label="A normal amount of milk">🍼🍼</span> : null }
            { this.state.bottleAmount === 2 ? <span role="img" id="feeding-amount-3" aria-label="A lot of milk">🍼🍼🍼</span> : null }
          </div>
  
          <input 
            className="mdl-slider mdl-js-slider" 
            id="feeding-amount"
            type="range"
            min="0"
            max="2"
            value={this.state.bottleAmount}
            onChange={this.addBottleValueChanged} 
            ref="bottle_slider" />
  
          <div className="mdl-dialog__actions">
            <button type="button" className="mdl-button" onClick={this.cancelAddBottle}>Cancel</button>
            <button type="button" className="mdl-button close" onClick={this.confirmAddBottle}>Add Feeding</button>
          </div>
  
        </dialog>
    
        <button className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored App-fab-bottom-right" onClick={this.addBottle}>
          <span role="img" aria-label="Add bottle">🍼</span>
        </button>
        
      </div>
    );
  }
}

export default BottleTab;