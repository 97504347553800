import Dispatcher from '../Dispatcher/Dispatcher.js';
import {EventEmitter} from "events";
import * as BabyTrackerActions from '../Actions/BabyTrackerActions.js';
import {fire} from '../utils/fire.js';

class ChildStore extends EventEmitter {
    
    constructor() {
        super();
        
        this.children = [];
        this.activeChild = null;
        
    }
    
    handleActions(action) {
        switch(action.type) {
            
            case BabyTrackerActions.BABY_TRACKER_ACTIONS.SET_ACTIVE_CHILD: {
                this.activeChild = this.children[action.value];
                this.emit("activeChildUpdated");
                
                break;
            }
            
            case BabyTrackerActions.BABY_TRACKER_ACTIONS.USER_LOGGED_OUT: {
                this.children = [];
                
                this.emit("childrenUpdated");
                
                break;
            }
            
            case BabyTrackerActions.BABY_TRACKER_ACTIONS.USER_LOGGED_IN: {
                var user = action.value;
                
                // See if the user exists already. Create him if he doesnt
                fire.database().ref('/users/' + user.uid).once('value').then((snapshot) => {
                
                    if((snapshot.val())) {

                    } else {

                        fire.database().ref('users/' + user.uid).set({
                            name: user.displayName || null,
                            email: user.email || null,
                            profile_picture : user.photoURL || null
                        });
                    
                    }
                    
                });
                
                // Monitor changes to the children of the user
                
                // Reset the array
                this.children = [];
                
                // Get the list of children monitored by the current logged in user
                var childrenRef = fire.database().ref('users/' + fire.auth().currentUser.uid + '/children');
                
                // Monitor the document for changes
                childrenRef.on('value', (snapshot) => {
                    
                    // Make a list of promises, one for each child
                    var promises = [];
                    
                    // For each reference, pull the child data
                    for(var k in snapshot.val()) {
                        var child = snapshot.val()[k];
                        
                        // Get the child data in a promise
                        promises.push(fire.database().ref('/children/' + child).once('value').then((newchild) => {
                            var addChild = newchild.val();
                            addChild['key'] = newchild.key;
                            
                            this.children.push(addChild);

                        }));
                    }
                    
                    // Join all the promises and then update the UI
                    Promise.all(promises).then(() => {
                        this.emit("childrenUpdated");
                    });

                });
                
                break;
            }
            
            case BabyTrackerActions.BABY_TRACKER_ACTIONS.ADD_CHILD: {

                var child = {
                    name: action.value,
                    owners: [fire.auth().currentUser.uid]
                };
                
                // Add the child
                var ref = fire.database().ref('children').push(child);
                
                // Get the user that pushed the child
                var ownerUid = fire.auth().currentUser.uid;
                
                // Make sure that child is in their list
                fire.database().ref('users/' + ownerUid + '/children').push(ref.key);
                
                this.children = [];
                
                break;
            }

            case BabyTrackerActions.BABY_TRACKER_ACTIONS.DELETE_CHILD: {
                // TODO: This doesn't work
                // It should simply delete the reference to the child 
                // from the user that initiated the action
                this.children.splice(action.value, 1);

                this.children = [];

                break;
            }

            case BabyTrackerActions.BABY_TRACKER_ACTIONS.ADD_CHILD_POPUP: {
                this.emit("addChildPopup");
                
                break;
            }

            default: {

            }
        }
        
    }

    getActiveChild() {
        return this.activeChild;
    }

    getChildren() {
        return this.children;
    }
}

const childStore = new ChildStore();
Dispatcher.register(childStore.handleActions.bind(childStore));
export default childStore;