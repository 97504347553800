import React, { Component } from 'react';
import './NavigationPane.css';

import * as BabyTrackerActions from '../Actions/BabyTrackerActions.js';
import ChildStore from '../Stores/ChildStore.js';

class NavigationPane extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      children: ChildStore.getChildren()
    };
    
    this.addChild = this.addChild.bind(this);
    this.updateChildren = this.updateChildren.bind(this);
    this.selectChild = this.selectChild.bind(this);
  }

  componentDidMount() {
    ChildStore.on("childrenUpdated", this.updateChildren);
  }

  componentWillUnmount() {
    ChildStore.on("childrenUpdated", this.updateChildren);
  }

  updateChildren() {
    this.setState({children: ChildStore.getChildren()});
  }

  addChild() {
    BabyTrackerActions.addChildPopup();
  }

  selectChild(index) {
    BabyTrackerActions.setActiveChild(index);
  }

  render() {
    return(
        <div className="mdl-layout__drawer">
          <span className="mdl-layout-title">Baby Tracker</span>
          <ul className="demo-list-icon mdl-list">
          
          {this.state.children.map((child, index) => (
              <li className="mdl-list__item selectable" key={index} onClick={() => this.selectChild(index)}>
                <span className="mdl-list__item-primary-content">
                <i className="material-icons mdl-list__item-icon">person</i>
                { child.name }
              </span>
              </li>
            )
          )}          

            <li className="mdl-list__item selectable" onClick={this.addChild}>
              <span className="mdl-list__item-primary-content">
              <i className="material-icons mdl-list__item-icon">add</i>
              Add a child
            </span>
            </li>
          </ul>
        </div>
    );
  }
}

export default NavigationPane;