import Dispatcher from '../Dispatcher/Dispatcher.js';
import {EventEmitter} from "events";

import * as BabyTrackerActions from '../Actions/BabyTrackerActions.js';

import ChildStore from '../Stores/ChildStore.js';

import {fire} from '../utils/fire.js';

class DiaperStore extends EventEmitter {
    
    constructor() {
        super();
        
        this.diapers = [];
        this.activeChild = null;
        this.diaperCallback = null;
          
        ChildStore.on('activeChildUpdated', () => {
            // Kill a previous callback
            if(this.diaperCallback) {
                fire.database().ref('/children/' + this.activeChild.key + '/diapers/').off('value', this.diaperCallback);
            }
            
            // Get the new active child
            this.activeChild = ChildStore.getActiveChild();
            
            // Watch for changes to diapers for this child
            this.diaperCallback = fire.database().ref('/children/' + this.activeChild.key + '/diapers/').on('value', (diapers) => {
                this.diapers = [];
                for(var k in diapers.val()) {
                    var diaper = diapers.val()[k];
                    diaper['key'] = k;
                    this.diapers.push(diaper);
                }

                this.emit("diapersUpdated");
            });
            
        });

    }
    
    handleActions(action) {
        switch(action.type) {
            case BabyTrackerActions.BABY_TRACKER_ACTIONS.ADD_DIAPER: {
                fire.database().ref('/children/' + this.activeChild.key + '/diapers/').push(action.value);

                break;
            }

            case BabyTrackerActions.BABY_TRACKER_ACTIONS.DELETE_DIAPER: {
                fire.database().ref('/children/' + this.activeChild.key + '/diapers/' + action.value).remove();

                break;
            }

            default: {

            }
        }
        
    }

    getDiapers() {
        return this.diapers;
    }
}

const diaperStore = new DiaperStore();
Dispatcher.register(diaperStore.handleActions.bind(diaperStore));
export default diaperStore;