import React, { Component } from 'react';
import './DiapersTab.css';

import * as BabyTrackerActions from '../Actions/BabyTrackerActions.js';
import DiaperStore from '../Stores/DiaperStore.js';

class DiapersTab extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      diapers: DiaperStore.getDiapers()
    };
    
    this.confirmAddDiaper = this.confirmAddDiaper.bind(this);
    this.cancelAddDiaper = this.cancelAddDiaper.bind(this);
    this.updateDiapers = this.updateDiapers.bind(this);
  }

  componentDidMount() {
    DiaperStore.on("diapersUpdated", this.updateDiapers);
  }

  componentWillUnmount() {
    DiaperStore.removeListener("diapersUpdated", this.updateDiapers);
  }

  updateDiapers() {
    this.setState({diapers: DiaperStore.getDiapers()});
  }

  addDiaper() {
    var dialog = document.getElementById('diaper-dialog');
    dialog.showModal();
  }
  
  confirmAddDiaper() {

    var diaper = {};
    
    diaper['poop'] = this.refs.poop_check.children[0].checked;
    diaper['pee'] = this.refs.pee_check.children[0].checked;
    diaper['blowout'] = this.refs.blowout_check.children[0].checked;
    diaper['timestamp'] = Date.now();
    
    BabyTrackerActions.addDiaper(diaper);
    
    this.refs.poop_check.MaterialCheckbox.uncheck();
    this.refs.pee_check.MaterialCheckbox.uncheck();
    this.refs.blowout_check.MaterialCheckbox.uncheck();
    
    var dialog = document.querySelector('dialog');
    dialog.close();
    
  }
  
  cancelAddDiaper() {

    this.refs.poop_check.MaterialCheckbox.uncheck();
    this.refs.pee_check.MaterialCheckbox.uncheck();
    this.refs.blowout_check.MaterialCheckbox.uncheck();
    
    var dialog = document.querySelector('dialog');
    dialog.close();

  }  
  
  deleteDiaper(index) {
    BabyTrackerActions.deleteDiaper(index);
  }
  
  printDate(timestamp) {
    var date = new Date(timestamp);
    
    const month = date.toLocaleString('en-us', { month: 'short' });
    const day = date.getDate();

    return(
      <span className="">{month} {day}</span>
      );
  }
  
  render(props) {
    
    return(
      <div className="page-content App-content">
        <ul className="mdl-list">
      
          {this.state.diapers.map((diaper, index) => (
              <li className="mdl-list__item" key={index}>
                <span className="mdl-list__item-primary-content">
                  { this.printDate(diaper.timestamp) }
                  { diaper.poop ? <span role="img" aria-label="Poop in diaper" className="diaper-icon diaper-list-content">💩</span> : null }
                  { diaper.pee ? <span role="img" aria-label="Pee in diaper" className="diaper-icon diaper-list-content">💦</span> : null }
                  { diaper.blowout ? <span role="img" aria-label="Blowout" className="diaper-icon diaper-list-content">🌋</span> : null }
                </span>
                <a className="mdl-list__item-secondary-action" href="#"><i className="material-icons delete-icon" onClick={() => this.deleteDiaper(diaper.key)}>delete</i></a>
              </li>
            )
          )} 
          
        </ul>
        
        <dialog className="mdl-dialog" id="diaper-dialog">
    
          <ul className="demo-list-item mdl-list">
          
            <li className="mdl-list__item">
              <span className="mdl-list__item-primary-content">
                <span role="img" aria-label="Poop in diaper" className="diaper-icon">💩</span>
                Poop
              </span>
              <span className="mdl-list__item-secondary-action">
                <label id="poop_check" className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" htmlFor="poop-checkbox" ref="poop_check">
                  <input type="checkbox" id="poop-checkbox" className="mdl-checkbox__input" />
                </label>
              </span>
            </li>
            
            <li className="mdl-list__item">
              <span className="mdl-list__item-primary-content">
                <span role="img" aria-label="Pee in diaper" className="diaper-icon">💦</span>
                Pee
              </span>
              <span className="mdl-list__item-secondary-action">
                <label id="pee_check" className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" htmlFor="pee-checkbox" ref="pee_check">
                  <input type="checkbox" id="pee-checkbox" className="mdl-checkbox__input" />
                </label>
              </span>
            </li>
            
            <li className="mdl-list__item">
              <span className="mdl-list__item-primary-content">
                <span role="img" aria-label="Blowout" className="diaper-icon">🌋</span>
                Blowout
              </span>
              <span className="mdl-list__item-secondary-action">
                <label id="blowout_check" className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" htmlFor="blowout-checkbox" ref="blowout_check">
                  <input type="checkbox" id="blowout-checkbox" className="mdl-checkbox__input" />
                </label>
              </span>
            </li>
          </ul>
    
          <div className="mdl-dialog__actions">
            <button type="button" className="mdl-button" onClick={this.cancelAddDiaper}>Cancel</button>
            <button type="button" className="mdl-button close" onClick={this.confirmAddDiaper}>Add Diaper</button>
          </div>
    
        </dialog>
      
        <button className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored App-fab-bottom-right" onClick={this.addDiaper}>
          <span role="img" aria-label="Add a diaper change">💩</span>
        </button>
      
      </div>
    );
  }
  
}

export default DiapersTab;