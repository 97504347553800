import React, { Component } from 'react';
import './App.css';

import * as BabyTrackerActions from './Actions/BabyTrackerActions.js';

import DiapersTab from './Components/DiapersTab.js';
import MeasurementsTab from './Components/MeasurementsTab.js';
import BottleTab from './Components/BottleTab.js';
import NavigationPane from './Components/NavigationPane.js';
import AddChildDialog from './Components/AddChildDialog.js';

import {fire, provider} from './utils/fire.js';

class App extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      user: null
    };
    
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.showToast = this.showToast.bind(this);
  }
  
  showToast(message) {
    var notification = document.querySelector('.mdl-js-snackbar');

    notification.MaterialSnackbar.showSnackbar(message);
  }
  
  componentWillMount() {
    
    fire.auth().getRedirectResult().then((result) => {
      
      this.setState({user: fire.auth().currentUser});

      BabyTrackerActions.userLoggedIn(fire.auth().currentUser);

      
    }).catch(function(error) {

      var errorMessage = error.message;
      console.log("error! " + errorMessage);
      
    });

  }

  signOut() {
    fire.auth().signOut();
    
    this.setState({user: null});
    
    BabyTrackerActions.userLoggedOut();


  }
  
  signIn() {
    fire.auth().signInWithRedirect(provider);
  }
  
  render() {
    return (
      
      <div className="mdl-layout mdl-js-layout mdl-layout--fixed-header
                  mdl-layout--fixed-tabs">
      
        <header className="mdl-layout__header">
      
          <div className="mdl-layout__header-row">
            <span className="mdl-layout-title">Diaper Duty</span>
            <div className="mdl-layout-spacer"></div>
            <nav className="mdl-navigation">
              {this.state.user ? (<a className="mdl-navigation__link" href="#" onClick={this.signOut}>Sign Out</a>) : (<a className="mdl-navigation__link" href="#" onClick={this.signIn}>Sign In</a>) }
              
            </nav>

          </div>
      
          <div className="mdl-layout__tab-bar mdl-js-ripple-effect">
            <a href="#diaper-tab" className="mdl-layout__tab is-active">Diapers</a>
            <a href="#measurements-tab" className="mdl-layout__tab">Measurements</a>
            <a href="#bottles-tab" className="mdl-layout__tab">Bottles</a>
          </div>
      
        </header>
      
        <NavigationPane />
      
        <main className="mdl-layout__content">
      
          <section className="mdl-layout__tab-panel is-active App-content" id="diaper-tab">
            <DiapersTab />
          </section>
      
          <section className="mdl-layout__tab-panel App-content" id="measurements-tab">
            <MeasurementsTab />
          </section>
          
          <section className="mdl-layout__tab-panel App-content" id="bottles-tab">
            <BottleTab />
          </section>
      
        </main>
      
        <AddChildDialog />
        
        <div aria-live="assertive" aria-atomic="true" aria-relevant="text" className="mdl-snackbar mdl-js-snackbar">
            <div className="mdl-snackbar__text"></div>
            <button type="button" className="mdl-snackbar__action"></button>
        </div>
        
      </div>
    );
  }
}

export default App;
