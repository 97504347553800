import React, { Component } from 'react';
import './MeasurementsTab.css';

import * as BabyTrackerActions from '../Actions/BabyTrackerActions.js';
import MeasurementsStore from '../Stores/MeasurementsStore.js';


class MeasurementsTab extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      addLength: '',
      addWeight: '',
      measurements: MeasurementsStore.getMeasurements()
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.confirmAddMeasurements= this.confirmAddMeasurements.bind(this);
    this.cancelAddMeasurements= this.cancelAddMeasurements.bind(this);
    this.updateMeasurements = this.updateMeasurements.bind(this);
    
  }

  componentDidMount() {
    MeasurementsStore.on("measurementsUpdated", this.updateMeasurements);
  }

  componentWillUnmount() {
    MeasurementsStore.removeListener("measurementsUpdated", this.updateMeasurements);
  }

  updateMeasurements() {
    this.setState({measurements: MeasurementsStore.getMeasurements()});
    
  }

  addMeasurements() {

    var dialog = document.getElementById('measurement-dialog');
    dialog.showModal();
  }
  
  confirmAddMeasurements() {

    var measurement = {};
    
    if(this.state.addLength) {
      measurement['length'] = this.state.addLength;
    }
    
    if(this.state.addWeight) {
      measurement['weight'] = this.state.addWeight;
    }
    
    measurement['timestamp'] = Date.now();
    
    BabyTrackerActions.addMeasurements(measurement);


    this.clearAddMeasurements();
    
    var dialog = document.getElementById('measurement-dialog');
    dialog.close();

  }
  
  cancelAddMeasurements() {
    this.clearAddMeasurements();

    var dialog = document.getElementById('measurement-dialog');
    dialog.close();
  }
  
  handleChange(event) {
    
    if(event.target.id === "addWeight") {
      this.setState( {addWeight: event.target.value} );
    }
    
    if(event.target.id === "addLength") {
      this.setState( {addLength: event.target.value} );
    }
  }
  
  clearAddMeasurements() {
    this.setState({addWeight: '', addLength: ''});
  }
  
  deleteMeasurements(index) {
    BabyTrackerActions.deleteMeasurements(index); 
  }
  
  printDate(timestamp) {
    var date = new Date(timestamp);
    
    const month = date.toLocaleString('en-us', { month: 'short' });
    const day = date.getDay();
    //const year = date.getYear();
    
    return(
      <span className="">{month} {day}</span>
      );
  }  
  
  render() {
    return(
        <div className="page-content">
        
          {this.state.measurements.map((measurement, index) => (
              <li className="mdl-list__item" key={index}>
                <span className="mdl-list__item-primary-content">
                  { this.printDate(measurement.timestamp) }
                  { measurement.length ? <span className="measurement-icon measurement-list-content">Length: { measurement.length } in. </span> : null }
                  { measurement.weight ? <span className="measurement-icon measurement-list-content">Weight: { measurement.weight } lbs. </span> : null }
                </span>
                <a className="mdl-list__item-secondary-action" href="#"><i className="material-icons delete-icon" onClick={() => this.deleteMeasurements(measurement.key)}>delete</i></a>
              </li>
            )
          )}     
          <dialog className="mdl-dialog" id="measurement-dialog">
    
            <div className="mdl-grid measurement-container">
              <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label measurement-input">
                <input id="addLength" className="mdl-textfield__input" type="text" pattern="-?[0-9]*(\.[0-9]+)?" onChange={this.handleChange} value={this.state.addLength} />
                <label className="mdl-textfield__label" htmlFor="height">Length (in.)</label>
              </div>  
              <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label measurement-input">
                <input id="addWeight" className="mdl-textfield__input" type="text" pattern="-?[0-9]*(\.[0-9]+)?" onChange={this.handleChange} value={this.state.addWeight} />
                <label className="mdl-textfield__label" htmlFor="height">Weight (lbs.)</label>
              </div>                  
            </div>
    
            <div className="mdl-dialog__actions">
              <button type="button" className="mdl-button" onClick={this.cancelAddMeasurements}>Cancel</button>
              <button type="button" className="mdl-button close" onClick={this.confirmAddMeasurements}>Add Measurements</button>
            </div>
    
          </dialog>
    
          <button className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored App-fab-bottom-right" onClick={this.addMeasurements}>
            <span role="img" aria-label="Add measurements">📏</span>
          </button>
          
        </div>
    );
  }
  
}

export default MeasurementsTab;