import Dispatcher from '../Dispatcher/Dispatcher.js';
import {EventEmitter} from "events";

import * as BabyTrackerActions from '../Actions/BabyTrackerActions.js';

import ChildStore from '../Stores/ChildStore.js';

import {fire} from '../utils/fire.js';

class BottleStore extends EventEmitter {
    
    constructor() {
        super();
        
        this.bottles = [];
        this.activeChild = null;
        this.bottleCallback = null;
          
        ChildStore.on('activeChildUpdated', () => {
            // Kill a previous callback
            if(this.bottleCallback) {
                fire.database().ref('/children/' + this.activeChild.key + '/bottles/').off('value', this.bottleCallback);
            }
            
            // Get the new active child
            this.activeChild = ChildStore.getActiveChild();
            
            // Watch for changes to diapers for this child
            this.bottleCallback = fire.database().ref('/children/' + this.activeChild.key + '/bottles/').on('value', (bottles) => {
                this.bottles = [];
                for(var k in bottles.val()) {
                    var bottle = bottles.val()[k];
                    bottle['key'] = k;
                    this.bottles.push(bottle);
                }

                this.emit("bottlesUpdated");
            });
            
        });    }
    
    handleActions(action) {
        switch(action.type) {
            case BabyTrackerActions.BABY_TRACKER_ACTIONS.ADD_BOTTLE: {
                fire.database().ref('/children/' + this.activeChild.key + '/bottles/').push(action.value);

                break;
            }
                
            case BabyTrackerActions.BABY_TRACKER_ACTIONS.DELETE_BOTTLE: {
                fire.database().ref('/children/' + this.activeChild.key + '/bottles/' + action.value).remove();

                break;
            }
                
            default: {

            }
            
        }
        
    }
    
    getBottles() {
        return this.bottles;
    }
}

const bottleStore = new BottleStore();
Dispatcher.register(bottleStore.handleActions.bind(bottleStore));
export default bottleStore;