import Dispatcher from '../Dispatcher/Dispatcher.js';

export const BABY_TRACKER_ACTIONS = {
  ADD_DIAPER: 'babyTrackerActions.AddDiaper',
  DELETE_DIAPER: 'babyTrackerActions.DeleteDiaper',
  ADD_MEASUREMENTS: 'babyTrackerActions.AddMeasurements',
  DELETE_MEASUREMENTS: 'babyTrackerActions.DeleteMeasurements',
  ADD_BOTTLE: 'babyTrackerActions.AddBottle',
  DELETE_BOTTLE: 'babyTrackerActions.DeleteBottle',
  ADD_CHILD: 'babyTrackerActions.AddChild',
  DELETE_CHILD: 'babyTrackerActions.DeleteChild',
  ADD_CHILD_POPUP: 'babyTrackerActions.AddChildPopup',
  USER_LOGGED_IN: 'babyTrackerActions.UserLoggedIn',
  USER_LOGGED_OUT: 'babyTrackerActions.UserLoggedOut',
  SET_ACTIVE_CHILD: 'babyTrackerActions.SetActiveChild'
};

export function setActiveChild(index) {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.SET_ACTIVE_CHILD,
        value: index
    });
}

export function userLoggedIn(user) {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.USER_LOGGED_IN,
        value: user
    });
}

export function userLoggedOut() {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.USER_LOGGED_OUT,
        value: null
    });
}

export function addChildPopup() {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.ADD_CHILD_POPUP,
        value: true
    });
}

export function addChild(child) {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.ADD_CHILD,
        value: child
    });
}

export function deleteChild(index) {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.DELETE_CHILD,
        value: index
    });
}

export function addDiaper(diaper) {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.ADD_DIAPER,
        value: diaper
    });
}

export function deleteDiaper(index) {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.DELETE_DIAPER,
        value: index
    });
}

export function addMeasurements(measurements) {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.ADD_MEASUREMENTS,
        value: measurements
    });
}

export function deleteMeasurements(index) {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.DELETE_MEASUREMENTS,
        value: index
    });
}

export function addBottle(bottle) {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.ADD_BOTTLE,
        value: bottle
    });
}

export function deleteBottle(index) {
    Dispatcher.dispatch({
        type: BABY_TRACKER_ACTIONS.DELETE_BOTTLE,
        value: index
    });
}