import React, { Component } from 'react';
import './NavigationPane.css';

import * as BabyTrackerActions from '../Actions/BabyTrackerActions.js';
import ChildStore from '../Stores/ChildStore.js';

class AddChildDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      childName: ''
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.closeAddChild = this.closeAddChild.bind(this);
    this.submitAddChild = this.submitAddChild.bind(this);
  }

  componentDidMount() {
    ChildStore.on("addChildPopup", this.showAddChild);
  }

  componentWillUnmount() {
    ChildStore.removeListener("addChildPopup", this.showAddChild);
  }

  showAddChild() {
    var dialog = document.querySelector('#add-child-dialog');

    dialog.showModal();
  }
  
  closeAddChild() {
    var dialog = document.querySelector('#add-child-dialog');

    dialog.close();
  }

  submitAddChild() {
    console.log(this.state.childName);
    
    BabyTrackerActions.addChild(this.state.childName, this.props.user);
    
    this.closeAddChild();
    
  }

  handleChange(e) {
    
    if(e.target.id === "child-name") {
      this.setState({childName: e.target.value});
    }
    
  }

  render() {
    return(
        <dialog className="mdl-dialog" id="add-child-dialog">
          <h4 className="mdl-dialog__title">Add child</h4>
          <div className="mdl-dialog__content">
            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
              <input className="mdl-textfield__input" type="text" id="child-name" value={this.state.childName} onChange={this.handleChange}/>
              <label className="mdl-textfield__label" htmlFor="child-name">Child's Name</label>
            </div>
          </div>
          <div className="mdl-dialog__actions">
            <button type="button" className="mdl-button" onClick={this.submitAddChild}>Add</button>
            <button type="button" className="mdl-button close" onClick={this.closeAddChild}>Cancel</button>
          </div>
        </dialog>
    );
  }
}

export default AddChildDialog;