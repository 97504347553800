import Dispatcher from '../Dispatcher/Dispatcher.js';
import {EventEmitter} from "events";

import * as BabyTrackerActions from '../Actions/BabyTrackerActions.js';

import ChildStore from '../Stores/ChildStore.js';

import {fire} from '../utils/fire.js';

class MeasurementsStore extends EventEmitter {
    
    constructor() {
        super();
        
        this.measurements = [];
        this.activeChild = null;
        this.measurementCallback = null;
          
        ChildStore.on('activeChildUpdated', () => {
            // Kill a previous callback
            if(this.mesurementCallback) {
                fire.database().ref('/children/' + this.activeChild.key + '/meaurements/').off('value', this.measurementsCallback);
            }
            
            // Get the new active child
            this.activeChild = ChildStore.getActiveChild();
            
            // Watch for changes to mesurements for this child
            this.measurementsCallback = fire.database().ref('/children/' + this.activeChild.key + '/measurements/').on('value', (measurements) => {
                this.measurements = [];
                for(var k in measurements.val()) {
                    var measurement = measurements.val()[k];
                    measurement['key'] = k;
                    this.measurements.push(measurement);
                }
                this.emit("measurementsUpdated");
            });
            
        });    }
    
    handleActions(action) {
        switch(action.type) {
            case BabyTrackerActions.BABY_TRACKER_ACTIONS.ADD_MEASUREMENTS: {
                fire.database().ref('/children/' + this.activeChild.key + '/measurements/').push(action.value);

                break;
            }
             
            case BabyTrackerActions.BABY_TRACKER_ACTIONS.DELETE_MEASUREMENTS: {
                fire.database().ref('/children/' + this.activeChild.key + '/measurements/' + action.value).remove();

                break;
            }
                
            default: {

            }
            
        }
        
    }
    
    getMeasurements() {
        return this.measurements;
    }
}

const measurementsStore = new MeasurementsStore();
Dispatcher.register(measurementsStore.handleActions.bind(measurementsStore));
export default measurementsStore;