import * as firebase from 'firebase/app';

import 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

  var config = {
    apiKey: "AIzaSyCwkKw3r8-GKk7b-SMjEbSTMfcxEAcozVc",
    authDomain: "diaperduty-c4e4d.firebaseapp.com",
    databaseURL: "https://diaperduty-c4e4d.firebaseio.com",
    projectId: "diaperduty-c4e4d",
    storageBucket: "diaperduty-c4e4d.appspot.com",
    messagingSenderId: "329473881403",
    appId: "1:329473881403:web:ad0ba624fa89efb6"
  };
  
var fire = firebase.initializeApp(config);
var provider = new firebase.auth.GoogleAuthProvider();

export { 
  fire,
  provider
};